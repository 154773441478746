<!--
 * @Description: 功能定义-命令新增
 * @Author: kecraft
 * @Date: 2024-01-04 18:07:19
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-27 10:25:03
 * @FilePath: /impact-iotos-console/src/views/product/components/feature/command/commandAdd.vue
-->
<template>
  <div class="command-add">
    <myDialog @close="handleClose" @ok="handleOk" :title="isEdit ? '编辑命令' : '创建命令'" width="700px">
      <el-form :rules="rules" ref="formCommandAdd" :model="form" label-position="left">
        <el-form-item label="命令名称：" prop="name" class="label-feather">
          <el-input v-model="form.name" style="width: 512px;" placeholder="请输入命令名称" />
        </el-form-item>
        <el-form-item label="标识符号：" prop="cmd" class="label-feather">
          <el-input v-model="form.cmd" style="width: 512px;" placeholder="请输入标识符(支持字母数字下划线，必须以字母开头)"
            :disabled="isEdit" />
        </el-form-item>
        <el-form-item label="命令类型：" prop="frameType" class="label-feather">
          <el-select v-model="form.frameType" placeholder="请选择命令类型" style="width: 512px"
            @change="handleCommandTypeChange">
            <el-option v-for="item in commandTypeList" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
      </el-form>
      <div class="transfer-box">
        <el-transfer v-model="form.params" :props="{
      key: 'param',
      label: 'name',
    }" :titles="['待选参数', '已选参数']" filterable filter-placeholder="请输入参数名称" :data="customList" />
      </div>
    </myDialog>
  </div>
</template>

<script setup>
import myDialog from "@/components/dialog/myDialog.vue";
import { reactive, ref, defineEmits, defineExpose } from "vue";
import { useRoute } from "vue-router";
import { ElMessage } from "element-plus";
import api from '@/api/api'
const route = useRoute();
const emit = defineEmits(["close"]);
const customList = ref([]);
const isEdit = ref(false);
const form = reactive({
  name: "", // 命令名称
  cmd: "", // 命令标志符号
  frameType: "", // 命令类型
  params: [],
})
const formCommandAdd = ref(null);
const rules = {
  name: [
    { required: true, message: "命令名称不能为空", trigger: "change" },
  ],
  cmd: [
    {
      required: true,
      validator: (r, v, c) => {
        var regex = /^[0-9a-zA-Z_-]{2,128}$/;
        if (!v) c(new Error("标识符参数，不能为空"));
        else if (!regex.test(v)) c(new Error("标识符长度2-128，允许的字符 a-zA-Z_0-9"));
        else c();
      },
      trigger: "change",
    },
  ],
  frameType: [
    { required: true, message: "命令类型不能为空", trigger: "change" },
  ],
}
const commandTypeList = ref([
  {
    value: "DEV_UP",
    label: "上报帧",
  },
  {
    value: "DEV_DOWN",
    label: "下发帧",
  }
])
const upList = ref([]); // 上报帧可选列表
const downList = ref([]); // 下发帧可选列表
const handleClose = () => {
  emit("close");
}
const handleOk = () => {
  formCommandAdd.value.validate((valid) => {
    if (valid) {
      if (form.params.length === 0) {
        ElMessage.error("已选参数不能少于1个")
        return false
      }
      if (isEdit.value) {
        eidtCommand();
      } else {
        addCommand();
      }
    } else {
      return false;
    }
  });
}
const addCommand = () => {
  const { pk } = route.query;
  if (!pk) return;
  api.protocolCmdAdd(pk, form).then(res => {
    if (res.code === "0") {
      ElMessage.success("创建成功!")
      emit("close", true);
    }
  })
}
const eidtCommand = () => {
  const { pk } = route.query;
  if (!pk) return;
  api.protocolCmdEdit(pk, form).then(res => {
    if (res.code === "0") {
      ElMessage.success("编辑成功!")
      emit("close", true);
    }
  })
}
/**
 * 
 * @param {*} params 
 * @param {*} row 
 * @param {*} status 是否是编辑 true:编辑 false:copy
 */
const init = (params, row, status) => {
  const list = JSON.parse(JSON.stringify(params));
  const up = []
  const down = []
  list.forEach(item => {
    if (item.frameType === "DEV_UP" || item.frameType === "DEV_UP_DOWN") {
      up.push(item);
    }
    if (item.frameType === "DEV_DOWN" || item.frameType === "DEV_UP_DOWN") {
      down.push(item);
    }
  })
  upList.value = up;
  downList.value = down;
  if (row) {
    if (status) {
      isEdit.value = true;
    }

    const oldCmd = JSON.parse(JSON.stringify(row));
    const { cmd, frameType, name, params } = oldCmd;
    if (frameType === "DEV_UP") {
      customList.value = upList.value;
    } else {
      customList.value = downList.value;
    }
    if (status) {
      form.cmd = cmd;
      form.name = name;
    } else {
      form.cmd = cmd + "_1";
      form.name = name + "_1";
    }
    form.frameType = frameType
    form.params = params;
  }
}

const handleCommandTypeChange = (v) => {
  form.params = []
  if (v === "DEV_UP") {
    customList.value = upList.value;
  } else {
    customList.value = downList.value;
  }
}

defineExpose({ init });
</script>

<style lang="less" scoped>
.transfer-box {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
</style>


<style lang="less">
.label-feather {
  .el-form-item__label {
    width: 100px;
  }
}
</style>