<!--
 * @Description: 自定义参数-新增
 * @Author: kecraft
 * @Date: 2024-01-03 17:51:59
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-28 11:04:24
 * @FilePath: /impact-iotos-console/src/views/product/components/feature/custom/customAdd.vue
-->
<template>
  <div class="custom-add">
    <myDialog @close="handleClose" @ok="handleOk" :title="isEdit ? '编辑自定义参数' : '创建自定义参数'" width="700px">
      <el-form :rules="rules" ref="formCustomAdd" :model="form" label-position="left">
        <el-form-item label="参数名称：" prop="name" class="label-feather">
          <el-input v-model="form.name" style="width: 512px;" placeholder="请输入参数名称" />
        </el-form-item>
        <el-form-item label="标识符号：" prop="param" class="label-feather">
          <el-input v-model="form.param" style="width: 512px;" placeholder="请输入标识符(支持字母数字下划线，必须以字母开头)"
            :disabled="isEdit" />
        </el-form-item>
        <el-form-item label="数据类型：" prop="dataType" class="label-feather">
          <el-select v-model="form.dataType" placeholder="请选择数据类型" style="width: 512px" @change="handleReset">
            <el-option v-for="item in dataTypeList" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="传输类型：" prop="frameType" class="label-feather">
          <el-select v-model="form.frameType" placeholder="请选择传输类型" style="width: 512px">
            <el-option v-for="item in frameTypeList" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="单位：" prop="unit" class="label-feather">
          <el-input v-model="form.unit" style="width: 512px;" placeholder="请输入单位" />
        </el-form-item>
      </el-form>
      <div class="check-container">
        <div class="form-title">数据校验</div>
        <div v-if="form.checkTypeChecked === 'ENUM'" style="color:#015ee0;cursor: pointer" @click="handleEnumAdd">新增枚举值
        </div>
      </div>
      <div class="ruleForm-container">
        <el-form :rules="rules" ref="formCustomAdd1" :model="form">
          <el-form-item label="校验方式" prop="checkTypeChecked" class="label-feather1">
            <el-select v-model="form.checkTypeChecked" placeholder="请选择传输类型" style="width: 512px"
              @change="handleCheckTypeChange">
              <el-option v-for="item in showCheckTypeList" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item label="最小值" prop="minValue" v-if="form.checkTypeChecked === 'RANGE'" class="label-feather1">
            <el-input-number v-model="form.minValue" style="width: 512px;" placeholder="请输入数值" />
          </el-form-item>
          <el-form-item label="最大值" prop="maxValue" v-if="form.checkTypeChecked === 'RANGE'" class="label-feather1">
            <el-input-number v-model="form.maxValue" style="width: 512px;" placeholder="请输入数值" />
          </el-form-item>
          <el-form-item label="最小长度" prop="minLength" v-if="form.checkTypeChecked === 'LENGTH'" class="label-feather1">
            <el-input-number v-model="form.minLength" style="width: 512px;" placeholder="请输入数值" />
          </el-form-item>
          <el-form-item label="最大长度" prop="maxLength" v-if="form.checkTypeChecked === 'LENGTH'" class="label-feather1">
            <el-input-number v-model="form.maxLength" style="width: 512px;" placeholder="请输入数值" />
          </el-form-item>
          <el-form-item v-if="form.checkTypeChecked === 'ENUM'" prop="enumList" label=" " class="label-feather1">
            <div class="width300">
              <el-scrollbar style="height: 100%" wrap-style="overflow-x:hidden;">
                <div v-for="(item, index) of form.enumList" :key="index">
                  <div class="enum-item">
                    <el-form-item :error="item.valueErrorMsg">
                      <el-input v-model="item.value" style="width: 212px;margin-right:20px" placeholder="请输入数值"
                        v-if="form.dataType === 'STRING'" />
                      <el-input-number v-model="item.value" style="width: 212px;margin-right:20px"
                        v-if="form.dataType === 'INT'" />
                      <el-input-number v-model="item.value" style="width: 212px;margin-right:20px"
                        v-if="form.dataType === 'FLOAT'" :precision="2" />
                    </el-form-item>
                    <el-form-item :error="item.descErrorMsg">
                      <el-input v-model="item.desc" style="width: 212px;margin-right:20px" placeholder="请输入备注" />
                    </el-form-item>
                    <el-icon @click="handleEnumDel(index)">
                      <Delete />
                    </el-icon>
                  </div>
                </div>
              </el-scrollbar>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </myDialog>
  </div>
</template>

<script setup>
import myDialog from "@/components/dialog/myDialog.vue";
import { Delete } from '@element-plus/icons-vue';
import { ElMessage } from "element-plus";
import { reactive, ref, defineEmits, defineExpose } from "vue";
import { useRoute } from "vue-router";
import { dataTypeList, checkTypeList, frameTypeListProtocol } from "@/hooks/productEnum";
import api from '@/api/api'
const emit = defineEmits(["close"]);
const route = useRoute();
const isEdit = ref(false);
const formCustomAdd = ref(null); // 产品信息校验
const formCustomAdd1 = ref(null); // 产品信息校验
const rules = {
  param: [
    {
      required: true,
      validator: (r, v, c) => {
        var regex = /^[0-9a-zA-Z_-]{2,128}$/;
        if (!v) c(new Error("标识符参数，不能为空"));
        else if (!regex.test(v)) c(new Error("标识符长度2-128，允许的字符 a-zA-Z_0-9"));
        else c();
      },
      trigger: "change",
    },
  ],
  name: [
    { required: true, message: "参数名称不能为空", trigger: "change" },
  ],
  dataType: [
    { required: true, message: "数据类型不能为空", trigger: "change" },
  ],
  frameType: [
    { required: true, message: "传输类型不能为空", trigger: "change" },
  ],
  checkTypeChecked: [
    { required: true, message: "校验方式不能为空", trigger: "change" },
  ],
  minValue: [
    { required: true, message: "最小值不能为空", trigger: "change" },
  ],
  maxValue: [
    { required: true, message: "最大值不能为空", trigger: "change" },
  ],
  minLength: [
    { required: true, message: "最小长度不能为空", trigger: "change" },
  ],
  maxLength: [
    { required: true, message: "最大长度不能为空", trigger: "change" },
  ],
}
const form = reactive({
  name: "", // 参数名称
  param: "", // 参数名称
  dataType: "", // 数据类型
  frameType: "", // 传输类型
  unit: "", // 单位
  checkTypeChecked: "", // 校验方式
  maxValue: "", // 最大值
  minValue: "", // 最小值
  minLength: "", // 最小长度
  maxLength: "", // 最大长度
  enumList: [],
});
// DEV_UP: 设备上报, DEV_DOWN:设备下发, DEV_UP_DOWN:设备上报或下发, INNER:内部
const frameTypeList = ref(frameTypeListProtocol)
// 数据校验类型,NONE:不校验, RANGE: 范围校验, ENUM: 枚举校验, LENGTH: 长度校验
const showCheckTypeList = ref(checkTypeList)
const handleReset = () => {
  form.checkTypeChecked = ""
  handleDataTypeChange();
}
const handleDataTypeChange = () => {
  const dataType = form.dataType
  /**
   *  1、字符串没有范围校验
   *  2、整数、浮点没有长度校验
   */
  if (dataType === "STRING") {
    showCheckTypeList.value = [
      {
        value: "NONE",
        label: "不校验",
      },
      {
        value: "ENUM",
        label: "枚举校验",
      },
      {
        value: "LENGTH",
        label: "长度校验",
      },
    ]
  } else if (dataType === "INT" || dataType === "FLOAT") {
    showCheckTypeList.value = [
      {
        value: "NONE",
        label: "不校验",
      },
      {
        value: "ENUM",
        label: "枚举校验",
      },
      {
        value: "RANGE",
        label: "范围校验",
      },
    ]
  } else {
    showCheckTypeList.value = checkTypeList
  }
}
/**
 * 
 * @param {*} row 
 * @param {*} status 是否是编辑 true:编辑 false:copy
 */
const init = (row, status) => {
  if (status) {
    isEdit.value = true;
  }
  const oldForm = JSON.parse(JSON.stringify(row));
  const { name, param, dataType, frameType, unit, checkType } = oldForm;
  if (status) {
    form.name = name;
    form.param = param;
  } else {
    form.name = name + "_1";
    form.param = param + "_1";
  }
  form.dataType = dataType;
  form.frameType = frameType;
  form.unit = unit;
  form.checkTypeChecked = checkType.type;
  if (checkType.type === "RANGE") {
    form.maxValue = checkType.max.value
    form.minValue = checkType.min.value
  } else if (checkType.type === "ENUM") {
    form.enumList = checkType.values
  } else if (checkType.type === "LENGTH") {
    form.maxLength = checkType.max.value
    form.minLength = checkType.min.value
  }
  handleDataTypeChange()
};
const handleClose = () => {
  emit("close", false);
}
const handleOk = () => {
  formCustomAdd.value.validate((valid) => {
    if (valid) {
      check1();
    } else {
      return false;
    }
  });
}
const check1 = () => {
  formCustomAdd1.value.validate((valid) => {
    if (valid) {
      if (form.checkTypeChecked === "NONE") {
        form.checkType = {
          type: "NONE",
        }
      } else if (form.checkTypeChecked === "RANGE") {
        form.checkType = {
          type: "RANGE",
          min: { value: form.minValue, desc: "最小值" },
          max: { value: form.maxValue, desc: "最大值" }
        }
      } else if (form.checkTypeChecked === "LENGTH") {
        form.checkType = {
          type: "LENGTH",
          min: { value: form.minLength, desc: "最小长度" },
          max: { value: form.maxLength, desc: "最大长度" }
        }
      } else if (form.checkTypeChecked === "ENUM") {
        let isTrue = true;
        form.enumList.forEach(item => {
          if (!item.value && form.dataType === "STRING") {
            if (form.dataType)
              item.valueErrorMsg = "请输入数值"
            isTrue = false;
            return;
          } else {
            item.valueErrorMsg = ""
          }

          if (!item.value && !(form.dataType !== "STRING" && item.value === 0)) {
            if (form.dataType)
              item.valueErrorMsg = "请输入数值"
            isTrue = false;
            return;
          } else {
            item.valueErrorMsg = ""
          }
          if (!item.desc) {
            item.descErrorMsg = "请输入备注"
            isTrue = false;
            return;
          } else {
            item.descErrorMsg = ""
          }
        })
        if (!isTrue) return;
        const realList = []
        form.enumList.forEach(item => {
          const obj = {
            value: item.value,
            desc: item.desc,
          }
          realList.push(obj);
        })

        form.checkType = {
          type: "ENUM",
          values: realList,
        }
      }
      if (isEdit.value) {
        editCustom();
      } else {
        addCustom();
      }
    } else {
      return false;
    }
  });
}
const addCustom = () => {
  const { pk } = route.query;
  if (!pk) return;
  api.protocolParamAdd(pk, form).then(res => {
    if (res.code === "0") {
      ElMessage.success("创建成功!")
      emit("close", true);
    }
  })
}
const editCustom = () => {
  const { pk } = route.query;
  if (!pk) return;
  api.protocolParamEdit(pk, form).then(res => {
    if (res.code === "0") {
      ElMessage.success("编辑成功!")
      emit("close", true);
    }
  })
};
const handleCheckTypeChange = () => {
  if (form.checkTypeChecked === "ENUM") {
    form.enumList = []
    let obj;
    if (form.dataType === "STRING") {
      obj = {
        desc: "",
        value: "",
      }
    } else {
      obj = {
        desc: "",
        value: 0,
      }
    }

    form.enumList.push(obj);
  }
}

const handleEnumAdd = () => {
  let obj = {
    desc: "",
    value: "",
  }
  form.enumList.push(obj);
}

const handleEnumDel = (index) => {
  if (form.enumList.length === 1) return;
  form.enumList.splice(index, 1);
}
defineExpose({ init });
</script>

<style lang="less" scoped>
.width300 {
  height: 150px;
}

.form-title {
  font-weight: bold;
}

.ruleForm-container {
  margin-top: 20px;
  box-sizing: border-box;
  padding: 20px;
  background: #f0f2f6;
}

.check-container {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.enum-item {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}

:deep(.el-form-item__content) {
  display: block;
}
</style>


<style lang="less">
.label-feather {
  .el-form-item__label {
    width: 100px;
  }
}

.label-feather1 {
  .el-form-item__label {
    width: 80px;
  }
}
</style>