<!--
 * @Description: 功能定义-导入功能定义
 * @Author: kecraft
 * @Date: 2024-01-10 10:09:38
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-27 10:15:56
 * @FilePath: /impact-iotos-console/src/views/product/components/feature/common/protocolImprot.vue
-->
<template>
  <div class="protocol-improt">
    <myDialog @close="handleClose" @ok="handleOk" title="导入功能定义">
      <div class="width700">
        <div class="form-item">功能定义内容：</div>
        <el-input v-model="textarea" :rows="10" type="textarea" placeholder="请输入功能定义内容" />
      </div>
    </myDialog>
  </div>
</template>

<script setup>
import myDialog from "@/components/dialog/myDialog.vue";
import { ref, defineEmits } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { useRoute } from "vue-router";
import api from '@/api/api'
const emit = defineEmits(["close"]);
const route = useRoute();
const textarea = ref(null);
const handleClose = () => {
  emit("close", false)
}
const handleOk = () => {
  if (!textarea.value) return;
  const { pk } = route.query;
  ElMessageBox.confirm("导入功能定义将会删除原有功能定义，确定导入？", "提示", {
    type: "warning",
    confirmButtonText: "确定",
    cancelButtonText: "取消",
  }).then(() => {
    const json = JSON.parse(textarea.value)
    const { params, cmds } = json
    const form = {
      pk,
      params,
      cmds,
    }
    api.protocolImportAdd(pk, form).then(({ code }) => {
      if (code == "0") {
        ElMessage.success("导入成功!")
        emit("close", true)
      }
    })
  })
}
</script>

<style lang="less" scoped>
.form-item {
  margin-bottom: 10px;
  font-size: 14px;
}
.width700{
  width: 700px;
}
</style>