/*
 * @Description: 产品枚举
 * @Author: kecraft
 * @Date: 2024-01-15 08:51:43
 * @LastEditors: kecraft
 * @LastEditTime: 2024-03-13 15:29:14
 * @FilePath: /impact-iotos-console/src/hooks/productEnum.js
 */

/**
 * 联网方式
 * WIFI, CELLUAR, NBIOT, ETH, OTHER, LORA
 */
const deviceLinkTypeList = [
  {
    key: "WIFI",
    label: "Wi-Fi",
  },
  {
    key: "NBIOT",
    label: "NB-IoT",
  },
  {
    key: "CELLUAR",
    label: "2/3/4/5G",
  },
  {
    key: "ETH",
    label: "以太网(有线)",
  },
  {
    key: "OTHER",
    label: "其他",
  },
  {
    key: "LORA",
    label: "LoRa",
  }
]

/**
 * 交互协议
 * MQTT, HTTP, TCP, UDP, COAP, LWM2M, NB_OPERATOR, OTHER, LORA
 */
const transferTypeList = [
  {
    key: "HTTP",
    label: "HTTP",
  },
  {
    key: "MQTT",
    label: "MQTT",
  },
  {
    key: "COAP",
    label: "CoAP",
  },
  {
    key: "LWM2M",
    label: "LwM2M",
  },
  {
    key: "NB_OPERATOR",
    label: "运营商转发",
  },
  {
    key: "LORA",
    label: "LoRaWAN",
  },
  {
    key: "OTHER",
    label: "其他",
  },
]

/**
 * 节点类型
 * GENERAL:设备-不接入网关(普通设备),SWITCH:中继,GATEWAY:网关,TERMINAL:设备-接入网关(网关子设备)
 */
const deviceTypeList = [
  {
    key: "GENERAL",
    label: "直连设备",
  },
  {
    key: "SWITCH",
    label: "中继",
  },
  {
    key: "GATEWAY",
    label: "网关",
  },
  {
    key: "TERMINAL",
    label: "终端子设备",
  }
]

/**
 * 数据格式
 * DLINK:标准格式, CUSTOM:自定义格式 
 */
const dataFormatList = [
  {
    key: "DLINK",
    label: "DLink",
  },
  {
    key: "CUSTOM",
    label: "自定义",
  }
]
/**
 * 功能参数校验模式
 * STRICT:严格模式,LOOSE:宽松模式,LESS:可以缺失,MORE:可以更多
 */
const dataCheckModeList = [
  {
    key: "STRICT",
    label: "严格模式",
  },
  {
    key: "LOOSE",
    label: "宽松模式",
  },
  {
    key: "LESS",
    label: "可以缺失",
  },
  {
    key: "MORE",
    label: "可以更多",
  },
]
/**
 * 数据类型
 * STRING、INT、FLOAT
 */
const dataTypeList = [
  {
    label: "字符串",
    value: "STRING",
  },
  {
    label: "整形",
    value: "INT",
  },
  {
    label: "浮点型",
    value: "FLOAT",
  },
]

/**
 * 帧类型
 * DEV_UP: 设备上报, DEV_DOWN:设备下发, DEV_UP_DOWN:设备上报或下发, INNER:内部
 */
const frameTypeList = [
  {
    label: "设备上报",
    value: "DEV_UP",
  },
  {
    label: "设备下发",
    value: "DEV_DOWN",
  },
  {
    label: "设备上报或下发",
    value: "DEV_UP_DOWN",
  },
  {
    label: "内部",
    value: "INNER",
  }
];

/**
 * 校验类型
 * NONE、RANGE、ENUM、LENGTH
 */
const checkTypeList = [
  {
    value: "NONE",
    label: "不校验",
  },
  {
    value: "RANGE",
    label: "范围校验",
  },
  {
    value: "ENUM",
    label: "枚举校验",
  },
  {
    value: "LENGTH",
    label: "长度校验",
  },
]

/**
 * 帧类型 (功能定义列表)
 */
const frameTypeListProtocol = [
  {
    value: "DEV_UP",
    label: "只上报",
  },
  {
    value: "DEV_DOWN",
    label: "只下发",
  },
  {
    value: "DEV_UP_DOWN",
    label: "可上报可下发",
  }
]
export {
  deviceLinkTypeList,
  transferTypeList,
  deviceTypeList,
  dataFormatList,
  dataCheckModeList,
  dataTypeList,
  frameTypeList,
  checkTypeList,
  frameTypeListProtocol,
}