<!--
 * @Description: 导入标准功能定义
 * @Author: kecraft
 * @Date: 2024-01-08 16:45:35
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-27 10:15:10
 * @FilePath: /impact-iotos-console/src/views/product/components/feature/common/standardImport.vue
-->
<template>
  <div class="standard-import">
    <myDialog @close="handleClose" @ok="handleOk" title="导入标准功能定义" width="800px" :footVisible="false">
      <el-form ref="formCommandAdd" :model="form" label-position="left">
        <el-form-item label="产品品类：" prop="name" class="label-feather">
          <el-input v-model="form.name" style="width: 612px;" placeholder="请选择" :disabled="true" />
        </el-form-item>
      </el-form>
      <el-tabs v-model="selectName" class="demo-tabs">
        <el-tab-pane label="参数" name="0">
          <div style="margin-top: 20px;">
            <el-table stripe :data="paramList" empty-text="暂无数据" style="width: 100%;" max-height="400">
              <el-table-column width="200" prop="name" label="参数名称" align="center" show-overflow-tooltip />
              <el-table-column min-width="120" label="标识符" prop="param" align="center" show-overflow-tooltip />
              <el-table-column width="140" label="数据类型" prop="dataType" align="center">
                <template #default="scope">
                  {{ getDataTypeStr(scope.row.dataType) }}
                </template>
              </el-table-column>
              <el-table-column width="200" label="传输类型" prop="frameType" align="center">
                <template #default="scope">
                  {{ getframeTypeStr(scope.row.frameType) || "--" }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="命令" name="1">
          <div style="margin-top: 20px;">
            <el-table stripe :data="cmdList" empty-text="暂无数据" style="width: 100%;" max-height="400">
              <el-table-column width="300" prop="name" label="命令名称" align="center" />
              <el-table-column min-width="120" label="标识符" prop="cmd" align="center" show-overflow-tooltip />
              <el-table-column width="200" label="命令类型" prop="frameType" align="center">
                <template #default="scope">
                  {{ getframeTypeStr(scope.row.frameType) || "--" }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>

      <div class="dialog__footer">
        <span @click="handleClose()" v-domPreventReClick>取消</span>
        <span @click="handleOk()" v-domPreventReClick :class="hasData ? '' : 'is_noclick'">确认</span>
      </div>
    </myDialog>
  </div>
</template>

<script setup>
import myDialog from "@/components/dialog/myDialog.vue";
import { onMounted, reactive, ref, defineEmits } from "vue";
import { useRoute } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { dataTypeList, frameTypeListProtocol } from "@/hooks/productEnum";
import api from '@/api/api'
const emit = defineEmits(["close"]);
const route = useRoute();
const cmdList = ref([]);
const paramList = ref([]);
const selectName = ref("0");

const frameTypeList = frameTypeListProtocol;
const getDataTypeStr = (dataType) => {
  let str = ''
  dataTypeList.forEach(item => {
    if (item.value === dataType) {
      str = item.label;
    }
  })
  return str;
}
const getframeTypeStr = (frameType) => {
  let str = "";
  frameTypeList.forEach(item => {
    if (item.value === frameType) {
      str = item.label;
    }
  })
  return str;
}
const form = reactive({
  name: ""
})
const hasData = ref(false);
const productKey = ref(null);
onMounted(() => {
  const { pk } = route.query;
  productKey.value = pk;
  api.getProductDetailsByPk(productKey.value).then(res => {
    if (res.code === "0") {
      const { categories } = res.res.data;
      if (categories && categories.length > 0) {
        const list = [];
        categories.forEach(item => {
          list.push(item.name);
        })
        form.name = list.join("/");
        const model = categories[categories.length - 1].model;
        if (model) {
          const json = JSON.parse(model)
          if (json) {
            const { cmds, params } = json;
            cmdList.value = cmds;
            paramList.value = params;
            hasData.value = true;
          }
        }

      }
    }
  })
})
const handleClose = () => {
  emit("close", false)
}
const handleOk = () => {
  if (!hasData.value || !productKey.value) return;
  ElMessageBox.confirm("导入标准功能定义将会删除原有功能定义，确定导入？", "提示", {
    type: "warning",
    confirmButtonText: "确定",
    cancelButtonText: "取消",
  }).then(() => {
    api.protocolImport(productKey.value).then(({ code }) => {
      if (code == "0") {
        ElMessage.success("导入成功!")
        emit("close", true)
      }
    })
  })
}
</script>

<style lang="less" scoped>
.dialog__footer {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;

  span {
    width: 96px;
    height: 40px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    cursor: pointer;
  }

  &>span:nth-child(1) {
    color: #333333;
    border: 1px solid #B7BBC0;
    margin-right: 17px;
  }

  &>span:nth-child(2) {
    background: #367CC5;
    color: #FFFFFF;
  }

  .is_noclick {
    cursor: not-allowed !important;
    background: #80aff0 !important;
  }
}
</style>

<style lang="less">
.label-feather {
  .el-form-item__label {
    width: 100px;
  }
}
</style>