<!--
 * @Description: 功能定义-自定义参数列表
 * @Author: kecraft
 * @Date: 2024-01-03 16:56:45
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-29 09:56:54
 * @FilePath: /impact-iotos-console/src/views/product/components/feature/custom/customList.vue
-->
<template>
  <div class="custom-list">
    <div class="btn-groups">
      <div class="btns-box">
        <div class="upload-btn btn1" @click="importStandard" v-if="!isLookOnly" v-domPreventReClick>
          <img src="@/assets/icon/upload_icon.png" />
          导⼊标准功能定义
        </div>
        <div class="upload-btn" @click="() => isImportAdd = true" v-if="!isLookOnly" v-domPreventReClick>
          <img src="@/assets/icon/upload_icon.png" />
          导⼊功能定义
        </div>
        <div class="upload-btn" @click="handleLook" v-domPreventReClick>
          <img src="@/assets/icon/look.png" class="look-icon" />
          查看功能定义
        </div>
        <div class="upload-btn" @click="gotoDocument" v-domPreventReClick>
          <img src="@/assets/drive/file.png" />
          查看协议⽂档
        </div>
      </div>
    </div>
    <div class="search-container">
      <div class="form-item">
        <el-input v-model="query.keyword" class="input-item" placeholder="请输入参数名称/标识符" :prefix-icon="Search" />
      </div>
      <div class="form-item" style="flex: 1;">
        <div class="search-btn" v-domPreventReClick @click="search">搜索</div>
        <div class="reset-btn" v-domPreventReClick @click="reset">重置</div>
      </div>
    </div>

    <div class="btn-container" v-if="!isLookOnly" v-domPreventReClick>
      <div class="btn-green" @click="addCustom">
        <img src="@/assets/icon/add.png">
        创建参数
      </div>
      <div class="btn-normal" @click="batchDel" v-if="selectList.length > 0" v-domPreventReClick>
        <img src="@/assets/icon/btn-del.png">
        <div plain>批量删除</div>
      </div>
      <div class="btn-default" v-else v-domPreventReClick>
        <img src="@/assets/icon/delect.png">
        批量删除
      </div>
    </div>
    <div class="tables">
      <el-table stripe :data="customList" empty-text="暂无数据" v-loading="isload" style="width: 100%;"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" />
        <el-table-column width="80" label="序号" align="center">
          <template #default="scope">
            <span>{{
          scope.$index + 1
        }}</span>
          </template>
        </el-table-column>
        <el-table-column width="120" label="参数名称" prop="name" align="center" show-overflow-tooltip />
        <el-table-column min-width="120" label="标识符" prop="param" align="center" show-overflow-tooltip />
        <el-table-column width="120" label="数据类型" prop="dataType" align="center">
          <template #default="scope">
            {{ getDataTypeStr(scope.row.dataType) || "--" }}
          </template>
        </el-table-column>
        <el-table-column width="170" label="传输类型" prop="frameType" align="center">
          <template #default="scope">
            {{ getframeTypeStr(scope.row.frameType) || "--" }}
          </template>
        </el-table-column>
        <el-table-column width="140" label="校验方式" prop="checkType" align="center">
          <template #default="scope">
            {{ getCheck(scope.row.checkType) || "--" }}
          </template>
        </el-table-column>
        <el-table-column min-width="160" label="属性" prop="checkType" align="center">
          <template #default="scope">
            <div v-if="scope.row.checkType.type !== 'ENUM'">
              {{ getTypeStr(scope.row) }}
            </div>
            <div v-else class="product-box">
              <span class="product-name" v-for="(item, index) of scope.row.checkType.values" :key="index">
                {{ item.value }}:{{ item.desc }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="80" label="单位" prop="unit" align="center" show-overflow-tooltip />
        <el-table-column min-width="140" label="操作" align="center" v-if="!isLookOnly">
          <template #default="scope">
            <div class="column-btn">
              <div class="options">
                <span link style="color:#015ee0;cursor: pointer;padding: 0 6px;" @click="handleParamEdit(scope.row)"
                  v-domPreventReClick>
                  修改
                </span>
                <span link style="color:#015ee0;cursor: pointer;padding: 0 6px;" @click="handleParamCopy(scope.row)"
                  v-domPreventReClick>
                  复制
                </span>
                <span link style="color:#fc2b2b;cursor: pointer;padding: 0 6px;" @click="handleParamDel(scope.row)"
                  v-domPreventReClick>
                  删除
                </span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <customAdd v-if="isAdd" @close="handleClose" ref="customAddRef" />
    <standardImport v-if="isImport" ref="standardImportRef" @close="handleImportClose" />
    <protocolImprot v-if="isImportAdd" @close="handleImportAddClose" />
    <protocolLook v-if="isLook" @close="handleLookClose" ref="protocolLookRef" />
  </div>
</template>

<script setup>
import customAdd from "./customAdd";
import standardImport from '../common/standardImport';
import protocolImprot from "../common/protocolImprot";
import protocolLook from "../common/protocolLook";
import { Search } from '@element-plus/icons-vue';
import { ElMessage, ElMessageBox } from "element-plus";
import { ref, onMounted, getCurrentInstance, nextTick, reactive, defineProps } from 'vue';
import { useRoute, useRouter } from "vue-router";
import api from '@/api/api';
const props = defineProps(['isLook']);
const query = reactive({
  keyword: ""
})
const router = useRouter();
const route = useRoute();
const isAdd = ref(false);
const customList = ref([]);
const isload = ref(false);
const isImport = ref(false);
const isImportAdd = ref(false);
const isLook = ref(false);
const allInfo = ref(null);
const checkTypeList = [
  {
    value: "NONE",
    label: "不校验",
  },
  {
    value: "RANGE",
    label: "范围校验",
  },
  {
    value: "ENUM",
    label: "枚举校验",
  },
  {
    value: "LENGTH",
    label: "长度校验",
  },
]
const dataTypeList = [
  {
    label: "字符串",
    value: "STRING",
  },
  {
    label: "整形",
    value: "INT",
  },
  {
    label: "浮点型",
    value: "FLOAT",
  },
]
const frameTypeListProtocol = [
  {
    value: "DEV_UP",
    label: "只上报",
  },
  {
    value: "DEV_DOWN",
    label: "只下发",
  },
  {
    value: "DEV_UP_DOWN",
    label: "可上报可下发",
  }
]

const getDataTypeStr = (dataType) => {
  let str = ''
  dataTypeList.forEach(item => {
    if (item.value === dataType) {
      str = item.label;
    }
  })
  return str;
}
const getframeTypeStr = (frameType) => {
  let str = "";
  frameTypeListProtocol.forEach(item => {
    if (item.value === frameType) {
      str = item.label;
    }
  })
  return str;
}
const getCheck = (checkType) => {
  if (!checkType) return;
  let str = ""
  checkTypeList.forEach(item => {
    if (item.value === checkType.type) {
      str = item.label;
    }
  })
  return str;
}

const getTypeStr = (row) => {
  if (!row || !row.checkType) return;
  const { type } = row.checkType
  if (type === "NONE") {
    return "-"
  } else if (type === "RANGE") {
    const { max, min } = row.checkType;
    return `[${min.value},${max.value}]`
  } else if (type === "LENGTH") {
    const { max, min } = row.checkType;
    return `[${min.value},${max.value}]`
  } else if (type === "ENUM") {
    let str = ""
    const { values } = row.checkType
    values.forEach((item, index) => {
      if (index !== values.length - 1) {
        str = str + `${item.value}: ${item.desc};   `
      } else {
        str = str + `${item.value}:${item.desc}`
      }

    })
    return str;
  } else {
    return "-";
  }
}
const addCustom = () => {
  isAdd.value = true;
};
const productKey = ref(null);
let currentInstance = "";
const isLookOnly = ref(false);
onMounted(() => {
  /**
   * 通过是否有PK判断是否是编辑
   */
  const isLook = props.isLook;
  if (isLook) {
    isLookOnly.value = true;
  }
  currentInstance = getCurrentInstance();
  const { pk } = route.query;
  productKey.value = pk;
  getInfo();
});

const getInfo = () => {
  isload.value = true;
  api.protocolModel(productKey.value).then(res => {
    if (res.code === "0") {
      const { params } = res.res.data;
      allInfo.value = res.res.data;
      const paramsFilter = [];
      const reg = query.keyword;
      params.forEach(item=>{
        if(item.name.includes(reg)||item.param.includes(reg)){
          paramsFilter.push(item);
        }
      })
      customList.value = paramsFilter;
      isload.value = false;
    }
  })
}

const search = () => {
  getInfo();
}

const reset = () => {
  query.keyword = "";
  getInfo();
}


const handleClose = (val) => {
  isAdd.value = false;
  if (val) {
    getInfo();
  }
}

const handleParamDel = (row) => {
  ElMessageBox.confirm("确定删除该参数吗？", "提示", {
    type: "warning",
    confirmButtonText: "确定",
    cancelButtonText: "取消",
  }).then(() => {
    if (productKey.value && row.param)
      api.protocolParamDel(productKey.value, row.param).then(({ code }) => {
        if (code == "0") {
          ElMessage.success("删除成功!")
          getInfo()
        }
      })
  })
}

const handleParamEdit = (row) => {
  isAdd.value = true;
  nextTick(() => {
    currentInstance.proxy.$refs.customAddRef.init(row, true);
  });
}

const handleParamCopy = (row) => {
  isAdd.value = true;
  nextTick(() => {
    currentInstance.proxy.$refs.customAddRef.init(row, false);
  });
}
const importStandard = () => {
  isImport.value = true;
}

const handleImportClose = (val) => {
  isImport.value = false;
  if (val) {
    getInfo()
  }
}

const handleImportAddClose = (val) => {
  isImportAdd.value = false;
  if (val) {
    getInfo()
  }
}

const handleLookClose = (val) => {
  isLook.value = false;
  if (val) {
    getInfo()
  }
}

const handleLook = () => {
  isLook.value = true;
  nextTick(() => {
    currentInstance.proxy.$refs.protocolLookRef.init(allInfo.value);
  });
}

const gotoDocument = () => {
  const { pk, name } = route.query;
  router.push({
    path: "/agreementDocument",
    query: { name, pk }
  })
}
const selectList = ref([]); // 选中的列表
const handleSelectionChange = (val) => {
  selectList.value = val;
}
const batchDel = () => {
  if (selectList.value.length === 0) return;
  ElMessageBox.confirm("确定删除这些参数吗？", "提示", {
    type: "warning",
    confirmButtonText: "确定",
    cancelButtonText: "取消",
  }).then(() => {
    const list = selectList.value;
    const cmdList = []
    list.forEach(item => {
      cmdList.push(item.param)
    })
    api.batchDelParam(productKey.value, {
      keys: cmdList
    }).then(res => {
      if (res.code === "0") {
        ElMessage.success("删除成功！")
        selectList.value = []
        getInfo();
      }
    })
  })
}

</script>

<style lang="less" scoped>
.product-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .product-name {
    display: inline-block;
    background: #DFDFDF;
    padding: 0 8px;
    line-height: 24px;
    border-radius: 4px;
    color: #606266;
    margin-right: 5px;
    margin-bottom: 5px;
  }
}

.input-item {
  width: 260px;
}

.custom-list {
  position: relative;
}

.btn-container {
  margin-top: 30px;
  display: flex;
  align-items: center;
}

.btns-box {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  top: -70px;

  .upload-btn {
    display: flex;
    align-items: center;
    width: 154px;
    height: 40px;
    box-sizing: border-box;
    border: 1px solid #AFB9CB;
    font-size: 16px;
    color: #333;
    justify-content: center;
    border-radius: 4px;
    margin-left: 10px;
    cursor: pointer;

    img {
      width: 14px;
      height: 14px;
      margin-right: 11px;
    }
  }

  .upload-btn:hover,
  .upload-btn:active {
    border: 2px solid #367CC5;
    background: #EBEFF6;
  }

  .btn1 {
    width: 193px !important;
  }

  .look-icon {
    width: 17px !important;
    height: 12px !important;
  }
}

.search-container {
  margin-top: 30px;
  display: flex;
  align-items: center;

  .form-item {
    display: flex;
    align-items: center;
    margin-right: 25px;

    .reset-btn {
      margin-left: 10px;
      cursor: pointer;
      width: 92px;
      height: 36px;
      justify-content: center;
      display: flex;
      align-items: center;
      border-radius: 4px;
      box-sizing: border-box;
      font-weight: lighter;
      border: 1px solid #AFB9CB;
      color: #333333;
      font-size: 16px;
    }

    .search-btn {
      margin-left: 10px;
      cursor: pointer;
      width: 92px;
      height: 36px;
      justify-content: center;
      display: flex;
      align-items: center;
      border-radius: 4px;
      box-sizing: border-box;
      background: #367CC5;
      font-weight: lighter;
      // border: 1px solid #015ee0;
      font-size: 14px;
      color: #FFFFFF;
      font-size: 16px;
    }

    .search-btn:hover,
    .search-btn:active {
      border: 1px solid #367CC5;
      background: #61A4E9;
      // color: #367CC5;
    }
  }

}

.column-btn {
  display: flex;
  justify-content: space-around;
}

.tables {
  margin-top: 20px;
}

.btn-green {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 136px;
  height: 40px;
  border-radius: 4px;
  background: #30AE7D;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: lighter;
  cursor: pointer;

  &>img {
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
}


.btn-normal {
  margin-left: 10px;
  box-sizing: border-box;
  border: 1px solid #B7BBC0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 136px;
  height: 40px;
  border-radius: 4px;
  color: #333333;
  font-size: 16px;
  font-weight: lighter;
  cursor: pointer;

  &>img {
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
}

.btn-default {
  margin-left: 10px;
  box-sizing: border-box;
  border: 1px solid #B7BBC0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 136px;
  height: 40px;
  border-radius: 4px;
  color: #333333;
  font-size: 16px;
  font-weight: lighter;
  opacity: 0.5;
  cursor: not-allowed;

  &>img {
    width: 18px;
    height: 18px;
    opacity: 0.5;
    margin-right: 4px;
  }
}

:deep(.el-table th.el-table__cell) {
  background-color: #F6F7F9;
  height: 45px;
  font-weight: normal;
  color: #000000;
}

:deep(.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell) {
  background-color: #FBFBFB;
}

:deep(.el-table td.el-table__cell div) {
  font-size: 16px;
  line-height: 50px;
}
</style>