<!--
 * @Description: 功能定义 - 查看功能定义
 * @Author: kecraft
 * @Date: 2024-01-10 10:20:25
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-27 10:17:25
 * @FilePath: /impact-iotos-console/src/views/product/components/feature/common/protocolLook.vue
-->
<template>
  <div class="protocol-look">
    <myDialog @close="handleClose" @ok="handleOk" title="查看功能定义" okTitle="下载">
      <div class="width700">
        <div class="form-item">
          <div>功能定义内容：</div>
          <el-button type="primary" text @click="copyToClipboard(showJson)">复制</el-button>
        </div>
        <div class="form-container">
          <el-scrollbar style="height: 100%" wrap-style="overflow-x:hidden;">
            <pre v-html="showJson"></pre>
          </el-scrollbar>
        </div>
      </div>
    </myDialog>
  </div>
</template>

<script setup>
import FileSaver from 'file-saver';
import myDialog from "@/components/dialog/myDialog.vue";
import { ElMessage } from "element-plus";
import { ref, defineEmits, defineExpose } from "vue";
import { useRoute } from "vue-router";
import { copyToClipboard } from "@/hooks/common";
const emit = defineEmits(["close"]);
const showJson = ref(null);
const route = useRoute();
const handleClose = () => {
  emit("close", false)
}
const handleOk = () => {
  const { name } = route.query;
  if (!showJson.value) return;
  const blob = new Blob([showJson.value], { type: "application/json;charset=utf-8" });
  FileSaver.saveAs(blob, "功能定义_" + name + '.json')
  ElMessage.success("下载成功!")
}

const init = (row) => {
  const newJson = JSON.parse(JSON.stringify(row));
  const { params, cmds } = newJson
  const json = {
    params,
    cmds,
  }
  const show = JSON.stringify(json, null, 2)
  showJson.value = show;
}
defineExpose({ init });
</script>

<style lang="less" scoped>
.form-item {
  margin-bottom: 10px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.form-container {
  height: 400px;
}
.width700{
  width: 700px;
}
</style>