<!--
 * @Description: 命令查看
 * @Author: kecraft
 * @Date: 2024-01-08 15:36:31
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-31 14:03:24
 * @FilePath: /impact-iotos-console/src/views/product/components/feature/command/commandCheck.vue
-->
<template>
  <div class="command-check">
    <myDialog @close="handleClose" @ok="handleOk" :title="title" width="800px">
      <div class="width940">

        <div class="check-container">
          <el-scrollbar style="height: 100%" wrap-style="overflow-x:hidden;">
            <div class="main-box">
              <div class="left-container">
                <div class="big-title">参数</div>
                <div v-for="(item, index) of inputList" :key="index" class="is-left">
                  <div class="search-item">{{ item.key }}</div>
                  <el-input v-model="item.value" @input="handleChange(item)" v-if="item.type === 'STRING'" />
                  <el-input-number v-model="item.value" @change="handleChange(item)" v-if="item.type === 'INT'"
                    style="width:100%" />
                  <el-input-number v-model="item.value" @change="handleChange(item)" :precision="2"
                    v-if="item.type === 'FLOAT'" style="width:100%" />
                </div>
              </div>
              <div>
                <div class="big-title">命令</div>
                <div class="copy-item" @click="copyToClipboard(showJson)">复制</div>
                <pre v-html="showJson"></pre>
              </div>
            </div>

          </el-scrollbar>
        </div>

      </div>
    </myDialog>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineExpose } from "vue";
import myDialog from "@/components/dialog/myDialog.vue";
import { useRoute } from "vue-router";
import { copyToClipboard } from "@/hooks/common";
const emit = defineEmits(["close"]);
const route = useRoute();
const title = ref(null);
const showJson = ref(null);
const handleClose = () => {
  emit("close", true);
}
const handleOk = () => {
  emit("close", true);
}
const inputList = ref([]);
const init = (row, paramsList) => {
  const { pk, devId } = route.query;
  const oldForm = JSON.parse(JSON.stringify(row));
  const { name, cmd, frameType, params } = oldForm;
  let json = {
    "action": frameType === "DEV_UP" ? 'devSend' : 'cloudSend',
    "msgId": "1",
    "pk": pk,
    "devId": devId,
    "data": {
      "cmd": cmd,
      "params": {}
    },
  }
  let inputs = []
  params.forEach(item => {
    // json.data.params[item] = ""
    let type = null
    paramsList.forEach(param => {
      if (param.param === item) {
        type = param.dataType;
      }
    })
    const value = type === 'STRING' ? '' : null
    let obj = {
      type: type,
      key: item,
      value: value,
    }
    inputs.push(obj);
  })
  inputList.value = inputs;
  showJson.value = JSON.stringify(json, null, 2)
  title.value = name + "示范";
}

const handleChange = (item) => {
  const json = JSON.parse(showJson.value);
  json.data.params[item.key] = item.value
  showJson.value = JSON.stringify(json, null, 2)
}
defineExpose({ init });
</script>

<style lang="less" scoped>
.big-title{
  font-size: 16px;
  color: #000000;
  font-weight: bold;
  margin-bottom: 16px;
}
.is-left {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.width940 {
  width: 940px;
}

.check-container {
  height: 500px;

  // display: flex;
  .main-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .main-box>div {
    width: 48%;
  }
}

.left-container {
  height: 500px;
  box-sizing: border-box;
  padding-right: 30px;
}

.search-item {
  width: 200px;
  margin-right: 20px;
}

.copy-item {
  margin-left: 340px;
  width: 60px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 10px;
  color: #015ee0;
  cursor: pointer;
}

.copy-item:hover,
.copy-item:active {
  background: rgba(1, 94, 224, .06);
}
</style>